<template lang="pug">
  .sliding-thumb.bg-yellow.overflow-hidden.hover_shadow-inner-red
    //- square sizer
    .relative(:class="[aspectRatio]")
      //- image (.bg-gray-900 to prevent shadow poking through...)
      figure.absolute.overlay-px.z-10.cursor-pointer.bg-gray-900.transition-transform.duration-400.bg-cover.bg-center(:data-dir="rand", :class="{'squish': squish}", @click="$emit('mediaClick')")
        slot(name="media")
        //- img.absolute.overlay.z-10.object-cover.object-center(:src="doc.data.index_thumbnail.square.url", :alt="doc.data.index_thumbnail.alt")

      header.absolute.overlay.z-0.flex(:class="tableClasses")
        //- squish space
        .flex-1x5.md_flex-1x4
        //- content
        .flex-1.relative
          slot

      //- outer
      slot(name="outer")
</template>

<script>
// import { mapGetters } from 'vuex'
import svgEye from '@/components/SVG-Eye'
import Btn from '@/components/Btn'
export default {
  name: 'SquishyThumb',
  components: { svgEye, Btn },
  props: {
    ratio: { type: String, default: '1:1' }
  },
  data () {
    return {
      squish: false, // Math.random() >= 0.25,
      rand: Math.floor(Math.random() * (3 - 0 + 1))
      // work: null
    }
  },
  computed: {
    // ...mapGetters(['weiToETH']),
    tableClasses () {
      // return {
      //   'pl-1x4': this.rand === 0,
      //   'pl-1x4 md_pl-0 md_pt-1x4': this.rand === 1,
      //   'pr-1x4': this.rand === 2,
      //   'pr-1x4 md_pr-0 md_pb-1x4': this.rand === 3
      // }
      const classes = {
        0: 'flex-row',
        1: 'flex-row md_flex-col',
        2: 'flex-row-reverse',
        3: 'flex-row-reverse md_flex-col-reverse'
      }
      return classes[this.rand]
    },
    aspectRatio () {
      const ratios = {
        '5:7': 'pb-ar-5x7',
        '1:1': 'pb-ar-1x1'
      }
      return ratios[this.ratio]
    }
  },
  methods: {
    open () {
      this.squish = true
    },
    close () {
      this.squish = false
    }
  },
  watch: {
    squish (squish) {
      if (squish) this.$emit('open')
    }
  }
  // methods: {
  //   async buy () {
  //     await this.$store.dispatch('buy', this.doc.uid / 1000000)
  //     this.getWork(true)
  //   },
  //   async getWork (flush) {
  //     this.work = await this.$store.dispatch('getWork', { id: this.doc?.uid / 1000000, flush })
  //   }
  // },
  // async created () {
  //   this.getWork()
  // }
}
</script>

<style scoped>
@import '../style/variables';

figure{
  /*transition:transform 500ms 100ms;
  transform-origin: top left;*/

  /* mobile, only left / right */
  &[data-dir="0"],
  &[data-dir="1"]{
    transform-origin:left center;
    &.squish{
      transform:scale(.25,1);
    }
  }

  &[data-dir="2"],
  &[data-dir="3"]{
    transform-origin:right center;
    &.squish{
      transform:scale(.25,1);
    }
  }

  @media (--breakpoint-md) {
    &[data-dir="1"]{
      transform-origin:top center;
      &.squish{
        transform:scale(1,.25);
      }
    }
    &[data-dir="3"]{
      transform-origin:bottom center;
      &.squish{
        transform:scale(1,.25);
      }
    }
  }
}
</style>
